import { ClientFormStatus } from './ClientFormStatus';
import { ClientFormUserRole } from './ClientFormUserRoles';

type ClientFormVersionHistory = {
  id: string;
  role: (typeof ClientFormUserRole)[keyof typeof ClientFormUserRole];
  status: ClientFormStatus;
  acceptedStatus: boolean;
  remark: string;
  activityKey: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activityPlaceholders: Record<string, any>;
  createdUtc: string;
  majorVersion: number;
  minorVersion: number;
  versionState: VersionState;
  clonedClientFormId: string | null;
};

export enum VersionState {
  Draft = 0,
  Master = 1,
  Superseded = 2,
}

export default ClientFormVersionHistory;
